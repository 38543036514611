<template>
  <v-dialog
    :model-value="dialogStore.isOpen"
    persistent
    width="fit-content"
    transition="dialog-top-transition"
    max-width="600px"
  >
    <v-card class="bg-white">
      <v-card-title
        class="d-flex justify-center w-full align-center bg-surface-lighten-1 text-primary"
      >
        <span class="title-modal font-weight-bold">{{
          dialogStore.isEditing
            ? $t('dashboard.appointment.dialogPopup.titleEdit')
            : $t('dashboard.appointment.dialogPopup.titleCreate')
        }}</span>
        <CloseIcon
          size="24"
          @click="dialogStore.toggleDialog"
          class="mr-4 close-icon cursor-pointer"
        />
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex flex-column ga-2 px-12 xxs-padding">
        <v-select
          v-if="1 < profileStore.profiles.length"
          v-model="dialogStore.selectedProfile"
          :auto-select-first="false"
          :custom-filter="() => true"
          :items="profileStore.profiles"
          :label="$t('dashboard.appointment.dialogPopup.labelProfile')"
          class="mb-2"
          clearable
          hide-details
          item-title="mainDiscipline.name"
          item-value="mainDiscipline.name"
          prepend-icon="mdi-account"
          return-object
          validate-on="lazy"
          density="compact"
          @update:model-value="onProfileChange"
        />
        <v-btn
          v-if="dialogStore.isNewPatient && !dialogStore.isEditing"
          @click="handleChoosePatientOlder"
          variant="text"
        >
          <div class="font-weight-bold d-flex align-center ga-3">
            <span>
              {{ $t('dashboard.appointment.dialogPopup.selectOlderPatient') }}
            </span>
          </div>
        </v-btn>
        <v-autocomplete
          v-if="!dialogStore.isNewPatient"
          v-model="dialogStore.selectedPatient"
          :error-messages="practitionerAppointmentStore.errorMessage('owner')"
          :label="
            $t('dashboard.appointment.dialogPopup.placeholderNamePatient')
          "
          :items="
            practitionerAppointmentStore.patients as Partial<
              NewPatientData | PatientAugmentedData
            >[]
          "
          :auto-select-first="false"
          :custom-filter="() => true"
          :disabled="dialogStore.isEditing"
          clearable
          class="mb-2"
          item-title="fullName"
          item-value="fullName"
          return-object
          hide-details
          validate-on="lazy"
          prepend-icon="mdi-account-multiple-outline"
          variant="underlined"
          @input="onInputPatient"
          @update:search="autocomletePatientByName"
          @update:model-value="
            handleSelectAutocomplete($event as unknown as PatientAugmentedData)
          "
          @update:menu="handlePatientMenuUpdate"
        >
          <template v-slot:append-inner="{ isActive }">
            <v-btn
              v-show="loadingPatient && isActive"
              :loading="loadingPatient"
              variant="text"
              icon="mdi-loading"
              size="small"
              disabled
            >
            </v-btn>
          </template>
          <template #item="{ item, props }">
            <v-list-item v-bind="props">
              <template #prepend="">
                <v-avatar
                  v-if="(item.raw as PatientAugmentedData).firstName"
                  color="surface-variant"
                  size="33"
                  >{{
                    (item.raw as PatientAugmentedData).firstName.slice(0, 1)
                  }}</v-avatar
                >
              </template>
              <div class="text-caption">{{ item.raw.email }}</div>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-btn
          v-if="!dialogStore.isNewPatient && !dialogStore.isEditing"
          @click="handleNewPatient"
          variant="text"
        >
          <div class="font-weight-bold d-flex align-center ga-3">
            <v-icon>mdi-plus</v-icon>
            <span>
              {{ $t('dashboard.appointment.dialogPopup.newPatient') }}
            </span>
          </div>
        </v-btn>

        <v-expand-transition
          v-if="dialogStore.isNewPatient && !dialogStore.isEditing"
        >
          <form ref="formNewPatient" class="mb-2 d-flex flex-column ga-4">
            <v-text-field
              v-model="dialogStore.newPatientFirstName"
              hide-details
              :error-messages="
                practitionerAppointmentStore.errorMessage('firstNamePatient')
              "
              prepend-icon="mdi-account-outline"
              :label="`${$t('dashboard.appointment.dialogPopup.firstName')}*`"
              density="compact"
              clearable
              :rules="required"
            ></v-text-field>
            <v-text-field
              v-model="dialogStore.newPatientLastName"
              hide-details
              :error-messages="
                practitionerAppointmentStore.errorMessage('lastNamePatient')
              "
              prepend-icon="mdi-id-card"
              :label="`${$t('dashboard.appointment.dialogPopup.lastName')}*`"
              density="compact"
              clearable
              :rules="required"
            ></v-text-field>
            <v-text-field
              v-model="newPatientMail"
              hide-details
              :error-messages="
                practitionerAppointmentStore.errorMessage('emailPatient')
              "
              prepend-icon="mdi-at"
              :label="`${$t('Email')}*`"
              density="compact"
              clearable
              :rules="emailRules"
            ></v-text-field>
          </form>
        </v-expand-transition>

        <div class="d-flex align-center justify-start ga-4 my-2">
          <img :src="meeting" alt="Meeting icon" width="24px" />
          <v-select
            v-model="dialogStore.selectedAppointmentReason"
            :items="dialogStore.fileteredAppointmentReason"
            rounded
            density="compact"
            item-title="label"
            item-value="id"
            return-object
            :error-messages="
              errorMessages(dialogStore.selectedAppointmentReason)
            "
            hide-details
            :label="
              $t('dashboard.appointment.dialogPopup.placeholderSelectMotive')
            "
            @update:model-value="updateEnd"
          >
            <template #item="{ props, item }">
              <v-list-item v-bind="props">
                <div class="text-caption">
                  {{
                    $t('dashboard.time-slot.dialogTimeSlot.duration', {
                      duration: item.raw.duration,
                    })
                  }}
                </div>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <span
          v-if="!dialogStore.fileteredAppointmentReason.length"
          style="font-size: 0.8em"
          class="text-red text-center font-italic"
        >
          {{ $t('dashboard.time-slot.dialogTimeSlot.messageErrorForMotif') }}
        </span>

        <div class="d-flex align-center justify-space-between ga-1">
          <SelectDateAndTime
            v-model:date="dialogStore.selectedDate"
            v-model:start="dialogStore.selectedStartTime"
            v-model:end="dialogStore.selectedEndTime"
            :end-read-only="true"
            @update:start="updateEnd"
          />
        </div>
        <div
          v-if="!isInFuture"
          class="text-center text-caption text-red font-italic"
        >
          {{ $t('dashboard.appointment.dialogPopup.errorIsInPast') }}
        </div>
        <div
          v-if="addressStore.addresses.length > 1"
          class="d-flex align-center ga-4 my-2"
        >
          <v-select
            v-model="dialogStore.selectedAddress"
            class="flex-grow-1"
            hide-details
            density="compact"
            prepend-icon="mdi-map-marker-outline"
            item-title="formattedAddress"
            item-value="formattedAddress"
            return-object
            :label="$t('dashboard.time-slot.yourAddress')"
            :items="addressStore.addresses"
          >
            <template v-slot:selection="{ item }">
              <span v-if="item.title.length < 50">{{ item.title }}</span>
              <span v-else class="text-black align-self-center">
                {{ item.title.slice(0, 50) }} ...
              </span>
            </template>
          </v-select>
        </div>

        <!-- For the moment, the practitioner can set acceptRemote even if the reason doesn't allow it -->
        <div class="remote-wrapper d-flex align-center justify-start ga-4 my-2">
          <label for="">{{
            $t('dashboard.appointment.dialogPopup.modality')
          }}</label>
          <v-radio-group
            class="mx-auto ga-2 flex accept-remote items-center"
            inline
            hide-details
            v-model="dialogStore.acceptRemote"
          >
            <v-radio
              color="blue"
              :label="$t('dashboard.time-slot.in-person')"
              value="in-person"
            ></v-radio>
            <v-radio
              color="blue"
              :label="$t('dashboard.time-slot.remote')"
              value="remote"
            ></v-radio>
          </v-radio-group>
        </div>

        <v-text-field
          v-if="dialogStore.acceptRemote == 'remote'"
          v-model="dialogStore.remoteAppointmentLink"
          hide-details
          :error-messages="
            practitionerAppointmentStore.errorMessage('meetingLink')
          "
          prepend-icon="mdi-video"
          :label="$t('dashboard.appointment.dialogPopup.remoteAppointmentLink')"
          density="compact"
          clearable
        ></v-text-field>

        <rich-editor
          v-model:content="dialogStore.description"
          contentType="html"
          :placeholder="
            $t('dashboard.appointment.dialogPopup.placeholderDescription')
          "
          :toolbar="[
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
          ]"
          :rules="[]"
          :error-messages="[]"
          color="secondary"
        >
        </rich-editor>

        <div class="d-flex justify-end ga-2 mt-4">
          <v-btn
            v-if="dialogStore.isEditing"
            class="px-6"
            variant="outlined"
            color="red-darken-1"
            @click="handleDelete"
          >
            {{ $t('common.delete') }}
          </v-btn>
          <v-btn
            class="px-6"
            variant="flat"
            color="secondary"
            :loading="practitionerAppointmentStore.loading"
            :disabled="!dialogStore.isEditing && !formValid"
            @click="btnForSaveInformation"
          >
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import '@holitime/schedule-x-theme-default/dist/date-picker.css'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useAppointmentReasonStore } from '@/store/appointment-reason/appointementReasonStore'
import SelectDateAndTime from './SelectDateAndTime.vue'
import { useDialogPractitionerAppointmentStore } from '@/store/practitioner-appointment/dialogAppointmentStore'
import { usePractitionerAppointmentStore } from '@/store/practitioner-appointment/practitionerAppointmentStore'
import meeting from '@/assets/practitioner-appointment/meeting.png'
import RichEditor from '@/components/inputs/RichEditor.vue'
import { useUserStore } from '@/store/user/userStore'
import { useAddressStore } from '@/store/address/addressStore'
import {
  NewPatientData,
  PatientAugmentedData,
} from '@/store/practitioner-appointment/practitionerAppointmentEvent'
import { useDebounceFn } from '@vueuse/core'
import dayjs from 'dayjs'
import ConfirmDialogService from '../feedback/confirmDialog/confirmDialogService'
import { useI18n } from 'vue-i18n'
import { useProfileStore } from '@/store/profile/profileStore'
import { useValidate } from '@/useValidate'
import CloseIcon from '../icons/CloseIcon.vue'

const { required } = useValidate()

const { t } = useI18n()
const userStore = useUserStore()
const addressStore = useAddressStore()
const profileStore = useProfileStore()
const dialogStore = useDialogPractitionerAppointmentStore()
const practitionerAppointmentStore = usePractitionerAppointmentStore()
const appointmentReasonStore = useAppointmentReasonStore()

const emailRules = computed(() => [
  (v: string) => !!v || t('login.error.emailRequired'),
  (v: string) => /.+@.+\..+/.test(v) || t('login.error.emailInvalid'),
])

const onProfileChange = (event: any) => {
  if (event === null)
    dialogStore.selectedAppointmentReason =
      dialogStore.fileteredAppointmentReason[0]
  dialogStore.selectedAppointmentReason =
    dialogStore.fileteredAppointmentReason.find(ap => ap.profile.id == event.id)
}

const newPatientMail = ref('')

watch(
  () => newPatientMail.value,
  () => {
    const errors = emailRules.value
      .map(rule => rule(newPatientMail.value))
      .filter(result => result !== true)

    if (errors.length > 0) {
      dialogStore.newPatientEmail = ''
    } else {
      dialogStore.newPatientEmail = newPatientMail.value.toLowerCase()
    }
  },
)

watch(
  () => dialogStore.isOpen,
  () => {
    newPatientMail.value = dialogStore.newPatientEmail
  },
)

const handlePatientMenuUpdate = async () => {
  if (0 === practitionerAppointmentStore.patients.length) {
    await practitionerAppointmentStore.fillPatients('*')
  }
}

function errorMessages(value: any) {
  if (!value) {
    return ['Une option doit être sélectionnée.']
  }
  return []
}

const btnForSaveInformation = () => {
  if (!dialogStore.isEditing) {
    dialogStore.createAppointment()
  } else {
    dialogStore.updateAppointment()
  }
}

onMounted(async () => {
  if (!addressStore.addresses || !addressStore.addresses.length) {
    // userStore.initAddresses()
    addressStore.initAddresses()
  }
  if (!profileStore.profiles || !profileStore.profiles.length) {
    // await userStore.initProfiles()
    await profileStore.initProfiles()
  }
  dialogStore.selectedProfile = profileStore.profiles[0] // select first profile by default
  if (!appointmentReasonStore.appointmentReasons) {
    await appointmentReasonStore.refreshAppointmentReasons()
  }
  // default selected motive
  dialogStore.selectedAppointmentReason =
    dialogStore.fileteredAppointmentReason[0]

  // default search for users
  autocomletePatientByName('')
})
const formNewPatient = ref(null)
const handleChoosePatientOlder = () => {
  dialogStore.isNewPatient = false
  autocomletePatientByName('')
  dialogStore.resetData()
}

const handleNewPatient = () => {
  dialogStore.isNewPatient = true
  nextTick(() => {
    const input = formNewPatient.value.querySelector(
      'input:not([type=hidden]),textarea:not([type=hidden])',
    )
    if (input) {
      setTimeout(() => {
        input.focus()
      }, 0)
    }
  })
}

const formValid = ref(false)

const isInFuture = computed(() =>
  dayjs(
    `${dialogStore.selectedDate} ${dialogStore.selectedStartTime}`,
    'YYYY-MM-DD HH:mm',
  ).isAfter(dayjs()),
)

dialogStore.$subscribe((mutation, state) => {
  if (!state.selectedAddress) {
    state.selectedAddress = addressStore.addresses[0]
  }

  /** The case the end time is before start time should never happen, in normal civilization
   * as the end time is calculated in function of the time of the appointment reason.
   *
   * */
  if (
    state.selectedPatient &&
    state.selectedPatient.email &&
    state.selectedPatient.firstName &&
    state.selectedPatient.lastName &&
    state.selectedAddress &&
    state.selectedAppointmentReason &&
    state.selectedDate &&
    state.acceptRemote &&
    isInFuture.value
  )
    formValid.value = true
  else {
    formValid.value = false
  }
})

const loadingPatient = ref(false)
const onInputPatient = () => {
  loadingPatient.value = true
}
const autocomletePatientByName = useDebounceFn(async name => {
  loadingPatient.value = true
  if (typeof name !== 'string') return
  await practitionerAppointmentStore.fillPatients(name.trim())
  loadingPatient.value = false
}, 500)

const handleSelectAutocomplete = (patientSelected: PatientAugmentedData) => {
  dialogStore.selectedPatient = patientSelected
}

const handleDelete = () => {
  ConfirmDialogService.confirm({
    title: t('dashboard.appointment.dialogPopup.confirmDeleteAppointment'),
    message: t(
      'dashboard.appointment.dialogPopup.confirmDeleteAppointmentQuetion',
    ),
    optionsConfirm: {
      onCancel() {},
      onConfirm() {
        practitionerAppointmentStore.handleDeleteAppointment(dialogStore.id)
      },
    },
  })
}

const updateEnd = () => {
  const duration = dialogStore.selectedAppointmentReason
    ? dialogStore.selectedAppointmentReason.duration
    : null
  if (!duration) return
  dialogStore.selectedEndTime = dayjs(
    dialogStore.selectedDate + ' ' + dialogStore.selectedStartTime,
    'YYYY-MM-DD H:mm',
  )
    .add(duration, 'minutes')
    .format('YYYY-MM-DD HH:mm')
    .split(' ')[1]
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 0;
}
.input-every-x-week {
  width: 66px;
  &:deep() input.v-field__input {
    text-align: center;
  }
}
.v-btn-toggle-custom {
  width: fit-content;
  border: 1px solid grey;
  :deep() {
    background-color: #fff;
  }
}
.select-motive {
  max-width: 80%;
}
.accept-remote {
  display: flex;
}
@media screen and (max-width: 540px) {
  .xxs-padding {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .remote-wrapper {
    flex-direction: column;
    gap: 4px !important;
  }
  .close-icon {
    right: -11px;
  }
  :deep() .ql-editor {
    font-size: 12px;
  }
}
</style>
