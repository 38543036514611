import { AxiosResponse } from 'axios'
import { ApiResponse } from '@/api/api.d'
import { SearchResponse } from './typesense.d'
import { typesenseClient } from '../typesenseClient'
import { useSearchFiltersStore } from '@/store/search-filters/searchFiltersStore'

export const findPractitioner = async (
  searchText: string,
  latitude: string,
  longitude: string,
  radius: string,
  discipline: string,
  priceOrder: 'asc' | 'desc' | 'all' = 'all',
  sortByOrder:
    | 'pertinence'
    | 'proximity'
    | 'price.asc'
    | 'price.desc'
    | 'opinions.asc'
    | 'opinions.desc' = 'pertinence',
  consultationType: 'remote' | 'in-person' | 'all' = 'all',
  // opinionOrder: 'asc' | 'desc' | 'all' = 'all',
  filter_by: string = '',
  page = 1,
  perPage = 15,
): Promise<ApiResponse<SearchResponse>> => {
  // Construire les filtres
  const filters = buildFilters(
    discipline,
    latitude,
    longitude,
    radius,
    consultationType,
    filter_by,
  )
  const searchFiltersStore = useSearchFiltersStore()

  // Construire les paramètres de tri
  const sortBy = buildSortBy(sortByOrder, consultationType, latitude, longitude)

  // Requête Typesense
  const response: AxiosResponse<SearchResponse> =
    await typesenseClient.get<SearchResponse>(
      `/collections/profiles/documents/search?q=${searchText}&preset=main_search&filter_by=${filters}&sort_by=${sortBy}&page=${page}&per_page=${perPage}&facet_by=disciplines.label&query_by=practitionerName,services,disciplines.label,embedding`,
    )

  if (response?.data?.facet_counts[0]?.counts) {
    // Récupère le tableau des disciplines et calcule la somme totale des counts
    const disciplines = response.data.facet_counts[0].counts
    const totalCount = disciplines.reduce(
      (acc: number, discipline: any) => acc + discipline.count,
      0,
    )

    // Formate les disciplines et ajoute un élément "total"
    searchFiltersStore.disciplines = [
      {
        value: '', // Valeur vide pour l'élément total
        highlighted: 'Toutes les disciplines', // Titre pour l'élément total
        count: totalCount, // Compte total
        formattedTitle: `Toutes les disciplines`, // Formattage du titre avec le count total
      },
      ...disciplines.map((discipline: any) => ({
        ...discipline, // Copie l'objet existant
        formattedTitle: `${discipline.highlighted} (${discipline.count})`, // Ajoute un titre formaté
      })),
    ]
  }

  return {
    status: response.status,
    data: response.data,
  }
}

/**
 * Construction des filtres pour la requête
 */
function buildFilters(
  discipline: string,
  latitude: string,
  longitude: string,
  radius: string,
  consultationType: 'remote' | 'in-person' | 'all',
  additionalFilters: string,
): string {
  const filters: string[] = []

  if (discipline) {
    filters.push(`disciplines.label:=[${discipline}]`)
  }

  if (consultationType === 'remote') {
    filters.push('remoteAllowed:=true')
  } else if (consultationType === 'in-person' || consultationType === 'all') {
    filters.push(`addresses.location:(${latitude},${longitude},${radius} km)`)
    if (consultationType === 'in-person') {
      filters.push('remoteAllowed:=false')
    }
  }

  if (additionalFilters) {
    filters.push(additionalFilters)
  }

  return filters.join(' && ')
}

/**
 * Construction des paramètres de tri pour la requête
 */
function buildSortBy(
  sortByOrder:
    | 'pertinence'
    | 'proximity'
    | 'price.asc'
    | 'price.desc'
    | 'opinions.asc'
    | 'opinions.desc',
  // opinionOrder: 'asc' | 'desc' | 'all',
  consultationType: 'remote' | 'in-person' | 'all',
  latitude: string,
  longitude: string,
): string {
  let sortCriteria: string[] = ['_text_match(buckets: 5):desc']

  if (
    (consultationType === 'in-person' || consultationType === 'all') &&
    sortByOrder == 'pertinence'
  ) {
    sortCriteria = []
    sortCriteria.push(`addresses.location(${latitude},${longitude}):asc`)
  }

  if (sortByOrder) {
    switch (sortByOrder) {
      case 'pertinence':
        sortCriteria = ['_text_match(buckets: 5):desc']
        if (consultationType === 'in-person' || consultationType === 'all') {
          sortCriteria.push(`addresses.location(${latitude},${longitude}):asc`)
        }
        sortCriteria.push(`ratioOpinionsPositive:desc`)
        break
      case 'proximity':
        sortCriteria = ['_text_match(buckets: 5):desc']
        sortCriteria.push(`addresses.location(${latitude},${longitude}):asc`)
        break
      case 'price.asc':
        sortCriteria = ['_text_match(buckets: 5):desc']
        sortCriteria.push(`priceMin:asc`)
        break
      case 'price.desc':
        sortCriteria = ['_text_match(buckets: 5):desc']
        sortCriteria.push(`priceMin:desc`)
        break
      case 'opinions.asc':
        sortCriteria = ['_text_match(buckets: 5):desc']
        sortCriteria.push(`ratioOpinionsPositive:asc`)
        break
      case 'opinions.desc':
        sortCriteria = ['_text_match(buckets: 5):desc']
        sortCriteria.push(`ratioOpinionsPositive:desc`)
        break

      default:
        sortCriteria = ['_text_match(buckets: 5):desc']
        sortCriteria.push(`addresses.location(${latitude},${longitude}):asc`)
        break
    }
  }

  // if (opinionOrder !== 'all') {
  //   sortCriteria.push(`ratioOpinionsPositive:${opinionOrder}`);
  // }

  return sortCriteria.join(',')
}
