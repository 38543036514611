<template>
  <v-container class="pa-0">
    <v-expand-transition>
      <v-card
        :color="color"
        class="rounded-xl pa-0 flat"
        style="box-shadow: none !important"
      >
        <v-card-title
          v-if="
            subscriptionStore.planChoise.id === 'monthly' &&
            subscriptionStore.hasDiscount
          "
          class="offer-title text-center d-flex justify-center"
          v-html="
            subscriptionStore.isChangingPlan
              ? subscriptionStore.changingPlan.description
              : subscriptionStore.planChoise.description
          "
        ></v-card-title>
        <v-card-text class="pa-4">
          <div class="d-sm-flex align-end">
            <div class="d-flex ga-2">
              <div
                class="d-flex align-center justify-center pa-5 bg-secondary rounded-lg"
                style="width: 52px; height: 54px"
              >
                <img
                  style="width: 18.14px; height: 26.39px"
                  :src="
                    subscriptionStore.isChangingPlan
                      ? subscriptionStore.changingPlan.icon
                      : subscriptionStore.planChoise.icon
                  "
                />
              </div>
              <div class="d-flex flex-column">
                <h3 class="choice-title">
                  {{
                    subscriptionStore.isChangingPlan
                      ? subscriptionStore.changingPlan.title
                      : subscriptionStore.planChoise.title
                  }}
                </h3>
                <p
                  v-if="
                    subscriptionStore.planChoise.subTitle1 ||
                    subscriptionStore.changingPlan.subTitle1
                  "
                  class="d-flex align-center ga-1 mb-1"
                  style="font-size: 11px"
                >
                  <img
                    style="width: 14px; height: 14px"
                    :src="
                      subscriptionStore.isChangingPlan
                        ? subscriptionStore.changingPlan.subTitle1.icon
                        : subscriptionStore.planChoise.subTitle1.icon
                    "
                  />
                  <span>{{
                    subscriptionStore.isChangingPlan
                      ? subscriptionStore.changingPlan.subTitle1.title
                      : subscriptionStore.planChoise.subTitle1.title
                  }}</span>
                </p>
                <p
                  v-if="
                    (subscriptionStore.isChangingPlan &&
                      subscriptionStore.changingPlan?.subTitle2?.icon) ||
                    (!subscriptionStore.isChangingPlan &&
                      subscriptionStore.planChoise?.subTitle2?.icon)
                  "
                  class="d-flex align-center ga-1"
                  style="font-size: 11px"
                >
                  <img
                    style="width: 14px; height: 14px"
                    :src="
                      subscriptionStore.isChangingPlan
                        ? subscriptionStore.changingPlan?.subTitle2?.icon
                        : subscriptionStore.planChoise?.subTitle2?.icon
                    "
                  />
                  <span>{{
                    subscriptionStore.isChangingPlan
                      ? subscriptionStore.changingPlan?.subTitle2?.title
                      : subscriptionStore.planChoise?.subTitle2?.title
                  }}</span>
                </p>
              </div>
            </div>

            <div class="d-flex justify-center mt-2 mt-sm-0">
              <span
                v-if="currentFormule && display"
                class="bg-accent current_subscription_btn"
                >{{
                  t('dashboard.subscription.current_subscription_btn')
                }}</span
              >
            </div>
          </div>
          <div class="d-flex align-center ga-3 mt-5 mb-3">
            <div class="price font-weight-bold">
              <span class="price-integer">€{{ priceInteger }}</span>
              <span v-if="decimalPrice" class="price-decimal">{{
                decimalPrice
              }}</span>
            </div>
            <div
              class="d-flex"
              v-if="
                subscriptionStore.planChoise.discount &&
                subscriptionStore.hasDiscount
              "
            >
              <div class="d-flex flex-column text-center mr-1">
                <template v-if="discountPercent > 0">
                  <div
                    v-if="priceHT || subscriptionStore.planChoise.old_price"
                    class="custom-strikethrough"
                  >
                    €{{
                      currentFormule
                        ? priceHT
                        : subscriptionStore.changingPlan.old_price
                    }}
                  </div>
                </template>
                <div class="taxe">
                  H.T
                  {{
                    subscriptionStore.planChoise.id == 'annual' ? '' : '/Mois'
                  }}
                </div>
              </div>
              <div
                v-if="discountPercent > 0"
                class="rounded-pill align-self-start py-0.5 px-1 text-white"
                style="background-color: #ff5a43"
              >
                -{{ discountPercent }}%
              </div>
            </div>
            <div class="align-self-center pa-0" v-else>HT</div>
          </div>
          <div style="font-size: 11px">{{ message }}</div>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-container>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import { useUserTvaStore } from '@/store/tva/userTvaStore'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useDisplay, useTheme } from 'vuetify'

const props = defineProps({
  color: {
    type: String,
    default: 'white',
  },
  currentFormule: {
    type: Boolean,
    default: false,
  },
  display: {
    type: Boolean,
    default: true,
  },
})

const { mdAndDown, mobile } = useDisplay()

const subscriptionStore = useSubscriptionStore()
const userTva = useUserTvaStore()
const route = useRoute()
const { t } = useI18n()

const the_price = computed(() => {
  return (
    actualPrice.value / (1 + subscriptionStore.subscriptionStatus['tva'] / 100)
  )
})

const actualPrice = computed(() => {
  return subscriptionStore.subscriptionStatus['actualPrice']
})

const priceInteger = computed(() => {
  if (props.currentFormule) {
    return Math.floor(the_price.value || subscriptionStore.planChoise.old_price)
  } else {
    if (subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0) {
      return Math.floor(
        subscriptionStore.isChangingPlan
          ? subscriptionStore.changingPlan.old_price
          : subscriptionStore.planChoise.old_price,
      )
    }

    if (
      subscriptionStore.subscriptionStatus['status'] === 'active' ||
      subscriptionStore.trialRemainingDays > 0
    ) {
      if (!subscriptionStore.alreadyMakePayment) {
        return subscriptionStore.isChangingPlan
          ? subscriptionStore.changingPlan.price
          : subscriptionStore.planChoise.price
      }
    }

    if (subscriptionStore.subscriptionStatus['status'] === 'incomplete') {
      return subscriptionStore.isChangingPlan
        ? subscriptionStore.changingPlan.price
        : subscriptionStore.planChoise.price
    }

    return Math.floor(
      subscriptionStore.isChangingPlan
        ? subscriptionStore.changingPlan.old_price
        : subscriptionStore.planChoise.old_price,
    )
  }
})

const decimalPrice = computed(() => {
  let decimalPart = ''

  if (props.currentFormule) {
    decimalPart = (the_price.value % 1).toFixed(2).substring(1)
  } else {
    if (
      subscriptionStore.subscriptionStatus['status'] === 'active' ||
      subscriptionStore.trialRemainingDays > 0
    ) {
      if (!subscriptionStore.alreadyMakePayment) {
        decimalPart = (
          subscriptionStore.isChangingPlan
            ? subscriptionStore.changingPlan.price
            : subscriptionStore.planChoise.price % 1
        )
          .toFixed(2)
          .substring(1)
      }
    }

    decimalPart = (subscriptionStore.planChoise.old_price % 1)
      .toFixed(2)
      .substring(1)
  }
  return decimalPart === '.00' ? '' : decimalPart
})

const priceHT = computed(() => {
  return subscriptionStore.subscriptionStatus['priceHT']
})

const price = computed(() => {
  let discount = 0

  if (props.currentFormule) {
    discount = subscriptionStore.subscriptionStatus['discountPercentage']
  } else {
    if (
      subscriptionStore.subscriptionStatus['status'] === 'active' ||
      subscriptionStore.trialRemainingDays > 0
    ) {
      if (!subscriptionStore.alreadyMakePayment) {
        discount = subscriptionStore.changingPlan.discount
      }
    }

    if (subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0) {
      discount = 0
    }

    if (subscriptionStore.subscriptionStatus['status'] === 'incomplete') {
      discount = subscriptionStore.changingPlan.discount
    }
  }

  const finalPrice =
    userTva.applyTva(
      subscriptionStore.isChangingPlan
        ? subscriptionStore.changingPlan.old_price
        : subscriptionStore.planChoise.old_price,
      subscriptionStore.isChangingPlan
        ? subscriptionStore.changingPlan.tva_percent
        : subscriptionStore.planChoise.tva_percent,
      discount,
    ) ?? 0

  return finalPrice
})

const message = computed(() => {
  const tva =
    (subscriptionStore.isChangingPlan
      ? subscriptionStore.changingPlan.tva_percent
      : subscriptionStore.planChoise.tva_percent) ?? 0
  let msg = ''

  if (
    subscriptionStore.planChoise.id == 'lifetime' ||
    subscriptionStore.planChoise.id == 'life'
  ) {
    msg = `TVA = ${tva}%  soit ${price.value}€ TTC facturé en une seule fois.`
  } else {
    msg = `TVA = ${tva}%  soit ${price.value}€ TTC facturé ${subscriptionStore.planChoise.id == 'annual' || subscriptionStore.changingPlan.id == 'annual' ? 'annuellement' : 'mensuellement'}.`
  }

  return msg
})

const discountPercent = computed(() => {
  if (props.currentFormule) {
    return subscriptionStore.subscriptionStatus['discountPercentage']
  }

  if (subscriptionStore.subscriptionStatus['monthsAlreadyPaid'] > 0) {
    return 0
  }

  if (
    subscriptionStore.subscriptionStatus['status'] === 'active' ||
    subscriptionStore.trialRemainingDays > 0
  ) {
    if (!subscriptionStore.alreadyMakePayment) {
      return subscriptionStore.isChangingPlan
        ? subscriptionStore.changingPlan.discount
        : subscriptionStore.planChoise.discount
    }
  }

  if (subscriptionStore.subscriptionStatus['status'] === 'incomplete') {
    return subscriptionStore.planChoise.discount
  }

  return 0
})
</script>

<style scoped lang="scss">
.offer-title {
  font-size: 12px !important;
  background-color: #004838;
  color: white;
  white-space: normal; /* Permet de passer à la ligne si le texte est trop long */
  word-wrap: break-word; /* Force le texte à s'emballer au-delà de la largeur */
  max-width: 100%;
}

.price {
  font-size: 32px;
  color: #004838;
}
.taxe {
  font-size: 10px;
  color: #616161;
}

.price-decimal {
  font-size: 20px;
  vertical-align: super;
}

.custom-strikethrough {
  position: relative;
  color: inherit;
  font-size: 14px;
}

.custom-strikethrough::after {
  content: '';
  position: absolute;
  left: 20%;
  right: 20%;
  top: 40%;
  border-top: 2px solid #ff5a43;
}

.choice-title {
  font-size: 14px;
  color: #004838;
}

.current_subscription_btn {
  font-size: 8px;
  font-weight: 600;
  border-radius: 40px;
  padding: 6px 8px;
}
@media (max-width: 599px) {
  .choice-title {
    font-size: 12px;
  }
}
</style>
