import { AxiosResponse } from 'axios'
import createHttpClient from '@/api/httpClient'
import { ApiResponse } from '@/api/api.d'
import {
  AccountInformationData,
  AccountUpdateParams,
  AccountUpdateResponse,
} from '@/api/account/account.d'
import {
  AddressDeleteParams,
  AddressInformationData,
  AddressParams,
  AddressResponse,
  CheckAppointmentResponse,
} from './address'

const httpClient = createHttpClient()

export const getInformations = async (): Promise<
  ApiResponse<AccountInformationData>
> => {
  const response: AxiosResponse<AccountInformationData> =
    await httpClient.get<AccountInformationData>('/account/informations')
  return {
    status: response.status,
    data: response.data,
  }
}

export const updateInformations = async (
  params: AccountUpdateParams,
): Promise<ApiResponse<AccountUpdateResponse>> => {
  const response: AxiosResponse<AccountUpdateResponse> =
    await httpClient.put<AccountUpdateResponse>('/account/informations', params)
  return {
    status: response.status,
    data: response.data,
  }
}

export const getAddresses = async (): Promise<
  ApiResponse<AddressInformationData[]>
> => {
  try {
    const response: AxiosResponse<AddressInformationData[]> =
      await httpClient.get<AddressInformationData[]>('/address/', {
        headers: { 'Content-Type': 'application/json' },
      })
    return {
      status: response.status,
      data: response.data,
    }
  } catch (_) {}
}

export const updateAddress = async (
  params: AddressParams,
): Promise<ApiResponse<AddressResponse>> => {
  try {
    const { id, ...payload } = params

    const response: AxiosResponse<AddressResponse> =
      await httpClient.put<AddressResponse>(`/address/${params.id}`, payload, {
        headers: { 'Content-Type': 'application/json' },
      })
    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    return {
      status: err.status,
      data: err.response.message,
    }
  }
}

export const storeAddress = async (
  params: AddressParams,
): Promise<ApiResponse<AddressResponse>> => {
  try {
    const response: AxiosResponse<AddressResponse> =
      await httpClient.post<AddressResponse>('/address/', params, {
        headers: { 'Content-Type': 'application/json' },
      })
    return {
      status: response.status,
      data: response.data,
    }
  } catch (err) {
    return {
      status: err.status,
      data: err.response.message,
    }
  }
}

export const deleteAddress = async (
  id: string,
  params: AddressDeleteParams,
): Promise<ApiResponse<AddressResponse[]>> => {
  const response: AxiosResponse<AddressResponse[]> = await httpClient.delete<
    AddressResponse[]
  >('/address/' + id, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
  })
  return {
    status: response.status,
    data: response.data,
  }
}

export const checkAppointment = async (
  id: string,
): Promise<ApiResponse<CheckAppointmentResponse[]>> => {
  const response: AxiosResponse<CheckAppointmentResponse[]> =
    await httpClient.get<CheckAppointmentResponse[]>(
      '/address/' + id + '/has-patient',
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  return {
    status: response.status,
    data: response.data,
  }
}

export const updatePassword = async (payload: any) => {
  try {
    const response: AxiosResponse = await httpClient.put(
      '/account/security',
      payload,
    )
    return {
      status: response.status,
      data: response.data,
    }
  } catch (_) {}
}
