import { Ref, computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import dayjs from 'dayjs'
import { AddressInformationData } from '@/api/account/address'
import {
  NewPatientData,
  PatientAugmentedData,
  PatientDataForCalendar,
  PractitionerAppointmentEvent,
  PractitionerAppointmentPostParams,
  PractitionerAppointmentPutParams,
} from './practitionerAppointmentEvent'
import { useUserStore } from '@/store/user/userStore'
import { useProfileStore } from '@/store/profile/profileStore'
import { FormattedAppointmentReasonInformationData } from '@/api/appointment-reason/appointment-reason'
import { usePractitionerAppointmentStore } from './practitionerAppointmentStore'
import { useAppointmentReasonStore } from '../appointment-reason/appointementReasonStore'
import { ProfileInformationData } from '@/api/profile/profile.d'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import EventBus from '@/utils/eventBus'
import { useAddressStore } from '../address/addressStore'

dayjs.extend(utc)
dayjs.extend(timezone)

const DEFAULT_TIMESLOT_DURATION = 15
const DEFAULT_TIMESLOT_UNIT = 'm'

export const useDialogPractitionerAppointmentStore = defineStore(
  'dialog-practitioner-appointment',
  () => {
    const isEditing: Ref<boolean> = ref(false)
    const id: Ref<string> = ref(null)
    const isOpen: Ref<boolean> = ref(false)

    const selectedProfile = ref<ProfileInformationData>(null)
    const selectedPatient: Ref<
      PatientAugmentedData | NewPatientData | PatientDataForCalendar
    > = ref(null)
    // Format YYYY-MM-DD for selectedDate
    const selectedDate: Ref<string> = ref(dayjs().format('YYYY-MM-DD'))
    const selectedStartTime: Ref<string | 'HH:mm'> = ref(
      dayjs().add(1, 'hour').minute(0).format('HH:mm'),
    )
    const selectedEndTime: Ref<string | 'HH:mm'> = computed(() => {
      if (selectedAppointmentReason.value) {
        return dayjs(`${selectedDate.value} ${selectedStartTime.value}`)
          .add(selectedAppointmentReason.value.duration, 'minutes')
          .format('HH:mm')
      } else {
        return dayjs()
          .add(DEFAULT_TIMESLOT_DURATION, DEFAULT_TIMESLOT_UNIT)
          .format('HH:mm')
      }
    })
    const selectedAddress: Ref<AddressInformationData> = ref(null)
    const selectedAppointmentReason: Ref<FormattedAppointmentReasonInformationData> =
      ref(null)
    const acceptRemote: Ref<'remote' | 'in-person'> = ref('in-person')
    const description: Ref<string> = ref('')

    const hasErrorDate = computed(() => {
      const start = dayjs(
        `${selectedDate.value} ${selectedStartTime.value}`,
        'YYYY-MM-DD HH:mm',
      )
      const end = dayjs(
        `${selectedDate.value} ${selectedEndTime.value}`,
        'YYYY-MM-DD HH:mm',
      )

      return end.isBefore(start) || end.isSame(start)
    })

    const isRemote = computed(() => acceptRemote.value == 'remote')
    const isPresential = computed(() => acceptRemote.value == 'in-person')

    const remoteAppointmentLink = ref('')

    /** start, end eg: 2024-12-12 09:00 */
    const openDialogWithDateTime = (start: string, end: string) => {
      resetData()
      selectedDate.value = start.split(' ')[0]
      selectedStartTime.value = start.split(' ')[1]
      isOpen.value = true
    }

    watch(isOpen, () => {
      // Reset data on close
      if (!isOpen.value) {
        resetData()
      }
    })

    const toggleDialog = async () => {
      // default params
      const today = dayjs().format('YYYY-MM-DD HH:mm')
      selectedDate.value = today.split(' ')[0]
      selectedStartTime.value = today.split(' ')[1]
      //
      isOpen.value = !isOpen.value
      await practitionerAppointmentStore.fillPatients('')
      if (isOpen.value && !isEditing.value) {
        resetData()
      }
    }

    /** New patient management */
    const isNewPatient = ref(false)
    const newPatientEmail = ref('')
    const newPatientLastName = ref('')
    const newPatientFirstName = ref('')
    const closeNewPatient = () => {
      isNewPatient.value = false
      newPatientEmail.value = ''
    }

    watch(
      [newPatientFirstName, newPatientLastName, newPatientEmail],
      (
        [newValFirstName, newValLastName, newValEmail],
        [oldValFirstName, oldValLastName, oldValEmail],
      ) => {
        if (isNewPatient.value) {
          selectedPatient.value = {
            firstName: newValFirstName,
            lastName: newValLastName,
            email: newValEmail,
          } as NewPatientData
        }
      },
    )

    const appointmentReasonStore = useAppointmentReasonStore()

    const fileteredAppointmentReason = computed(() => {
      return appointmentReasonStore.appointmentReasons.filter(reason =>
        selectedProfile.value.id ? true : true,
      )
    })

    const resetData = () => {
      id.value = null
      isEditing.value = false
      // selectedProfile.value = null
      selectedAddress.value = null
      selectedDate.value = dayjs().format('YYYY-MM-DD')
      selectedStartTime.value = dayjs().add(1, 'hour').minute(0).format('HH:mm')
      selectedAppointmentReason.value = fileteredAppointmentReason.value[0]
      acceptRemote.value = 'in-person'
      closeNewPatient()
      selectedPatient.value = null
      description.value = ''
      newPatientLastName.value = ''
      newPatientFirstName.value = ''
      remoteAppointmentLink.value = ''
      newPatientEmail.value = ''
    }

    const practitionerAppointmentStore = usePractitionerAppointmentStore()

    const createAppointment = () => {
      const createPayload: PractitionerAppointmentPostParams = {
        owner: (selectedPatient.value as PatientAugmentedData).id
          ? (selectedPatient.value as PatientAugmentedData).id
          : null,
        firstNamePatient: isNewPatient.value
          ? newPatientFirstName.value
          : selectedPatient.value.firstName,
        lastNamePatient: isNewPatient.value
          ? newPatientLastName.value
          : selectedPatient.value.lastName,
        emailPatient: isNewPatient.value
          ? newPatientEmail.value
          : selectedPatient.value.email,
        address: selectedAddress.value.id,
        appointmentReason: selectedAppointmentReason.value.id,
        dateTime: dayjs(
          selectedDate.value +
            ' ' +
            (selectedStartTime.value.split(':')[0].length < 2
              ? '0' + selectedStartTime.value
              : selectedStartTime.value) +
            ':00',
        ).format('YYYY-MM-DD HH:mm'),
        remote: isRemote.value,
        presential: isPresential.value,
        comment: description.value,
        meetingLink: remoteAppointmentLink.value,
        timezone: dayjs.tz.guess(),
      }
      practitionerAppointmentStore.handleCreateAppointment(createPayload)
    }
    const toggleDialogModify = (value: Ref<PractitionerAppointmentEvent>) => {
      isOpen.value = !isOpen.value
      isEditing.value = !isEditing.value
      if (value) {
        selectedPatient.value = value.value.patient
        const {
          appointmentReason: { profile },
        } = value.value
        const { profiles } = useProfileStore()
        const { addresses } = useAddressStore()
        selectedProfile.value = profiles.find(item => item.id === profile.id)
        selectedAddress.value = addresses.find(
          item => item.id === value.value.address.id,
        )
        selectedAppointmentReason.value = value.value.appointmentReason
        acceptRemote.value = value.value.acceptRemote
        description.value = value.value.description
        selectedDate.value = value.value.start.split(' ')[0]
        selectedStartTime.value = value.value.start.split(' ')[1]
        selectedEndTime.value = value.value.end.split(' ')[1]
        remoteAppointmentLink.value = value.value.meetingLink
        id.value = value.value.id
      }
    }
    const updateAppointment = async () => {
      // const updatePayload: PractitionerAppointmentPutParams = {
      //   appointmentReason: selectedAppointmentReason.value.id,
      //   dateTime: selectedDate.value + ' ' + selectedStartTime.value,
      //   remote: isRemote.value,
      //   presential: isPresential.value,
      //   comment: description.value,
      // }
      const updatePayload: PractitionerAppointmentPutParams = {
        appointmentReason: selectedAppointmentReason.value.id,
        dateTime: dayjs(
          selectedDate.value +
            ' ' +
            (selectedStartTime.value.split(':')[0].length < 2
              ? '0' + selectedStartTime.value
              : selectedStartTime.value) +
            ':00',
        ).format('YYYY-MM-DD HH:mm'),
        remote: isRemote.value,
        presential: isPresential.value,
        comment: description.value,
        timezone: dayjs.tz.guess(),
        meetingLink: remoteAppointmentLink.value,
        address: selectedAddress.value.id,
      }
      await practitionerAppointmentStore.handleUpdateAppointment(
        id.value,
        updatePayload,
      )
      toggleDialogModify(null)
      EventBus.emit('close-dialog-details')
    }

    return {
      id,
      isOpen,
      hasErrorDate,
      isEditing,
      selectedProfile,
      selectedAddress,
      selectedDate,
      selectedStartTime,
      selectedEndTime,
      selectedPatient,
      selectedAppointmentReason,
      acceptRemote,
      description,
      isRemote,
      isPresential,
      isNewPatient,
      newPatientEmail,
      newPatientLastName,
      newPatientFirstName,
      fileteredAppointmentReason,
      remoteAppointmentLink,
      closeNewPatient,
      createAppointment,
      updateAppointment,
      openDialogWithDateTime,
      resetData,
      toggleDialog,
      toggleDialogModify,
    }
  },
)
